import React, { useEffect, useState } from 'react'
import CustomerTable from './CustomerTable'
import CustomerSenseAiBarChart from '../MyKpisPage/CustomerSenseAiBarChart'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import Map from '../components/Map'
import LocationTable from './LocationTable'
import SessionLogsTable from '../MyKpisPage/SessionLogsTable'
import BarChartSessionLogs from '../MyKpisPage/BarChartSessionLogs'

function CustomersPage() {
    const [customerData, setCustomerData] = useState([])
    const [stateData, setStateData] = useState([])
    const [sessionLogs, setSessionLogs] = useState([])

    useEffect(() => {
        getData(apiLink + '/v1/internal/dashboard/data?show_all_customers=true')
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data){
                // console.log('customer',data.data)
              setCustomerData(data.data)
            } 
          }
        });
        getData(apiLink + '/v1/internal/dashboard/state_counts')
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data){
                // console.log('State',data.data)
                setStateData(data.data)
            } 
          }
        });

        getData(apiLink + '/v1/internal/session_logs')
        .then(async response => {
        if(response && response.ok ){
            const data = await response.json();
            if(data && data.data){
                // console.log('Logs',data.data)
            setSessionLogs(data.data)

            } 
        }
        });
    }, [])

    
  return (
    <div className={`px-4 sm:px-1 sm:pl-6 sm:pr-3 py-1 min-h-[calc(100vh-10rem)] max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide`}>
            <CustomerTable customerData={customerData} />
        
    </div>
  )
}

export default CustomersPage